import { SVGProps, Ref, forwardRef } from 'react'

const SvgTwitter = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M23.467 5.272a9.563 9.563 0 0 1-2.765.764 4.854 4.854 0 0 0 2.116-2.682c-.93.556-1.96.959-3.057 1.177A4.794 4.794 0 0 0 16.247 3c-2.66 0-4.815 2.17-4.815 4.846 0 .38.043.75.125 1.105-4.001-.203-7.549-2.133-9.923-5.065a4.844 4.844 0 0 0-.651 2.436 4.85 4.85 0 0 0 2.14 4.034 4.749 4.749 0 0 1-2.179-.604v.061a4.844 4.844 0 0 0 3.862 4.753 4.803 4.803 0 0 1-2.175.083c.613 1.925 2.39 3.327 4.497 3.366A9.62 9.62 0 0 1 0 20.022 13.573 13.573 0 0 0 7.381 22.2c8.855 0 13.698-7.386 13.698-13.791 0-.21-.004-.42-.015-.628a9.789 9.789 0 0 0 2.403-2.509Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgTwitter)
export default ForwardRef
